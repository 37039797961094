import React, { Component, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import Storage from '../../services/Storage';

export const TermsAcceptModal = ({ isTermModal, onClose, getData, isView = false }) => {
  // const isTermModal = Storage.getIsTermConditionModal();
  const [isAccept, setIsAccept] = useState(false);

  useEffect(() => {
    if (isTermModal) {
      setIsAccept(false);
    }
  }, [isTermModal])

  const handleCheckboxChange = (event) => {
    setIsAccept(event.target.checked);
  };

  const handleSubmitTerms = () => {
    console.log("11111111111111111111")
    if (isAccept && getData) {
      console.log("2222222222222222222")
      getData()
    }
  }

  return (
    <Modal
      isOpen={isTermModal}
      toggle={onClose}
      centered
      scrollable
    >
      <ModalHeader>TERMS AND CONDITIONS</ModalHeader>
      <ModalBody style={{ paddingLeft: '0', paddingRight: '0' }}>
        <div className='termsCondition'>
          <ul>
            <li>
              The enrollment to this program is by invitation only and JK Tyre & industries Ltd. holds sole discretion in
              selecting the members to be enrolled.
            </li>
            <li>
              This scheme is available only for selected member (channel partner) in select cities of India. Only direct
              purchase from JK Tyre & Industries Ltd. will be eligible for earning points in the program.
            </li>
            <li>
              This program is valid on JK Tyre PCR, Truck Bus Radial/Bias, LCV, SCV, 2/3 Wheeler & OTR Tyres as well Retread
              products on selected SKUs as mentioned in the CRM policy.
            </li>
            <li>Points or Reward of the Gift Catalogue cannot be exchanged, transferred or redeemed for cash or credit not.
            </li>

            <li>
              Once redemption is announced points can be redeemed for an amount equivalent to or less than the points
              accumulated at the time of redemption request.
            </li>
            <li>
              JK Tyre & Industries Ltd. dose not have obligation to reveal the value of points and rewards to the member.
            </li>
            <li>
              Computation of points by JK Tyre & Industries Ltd. will be final, conclusive and binding on the members and will
              not be called in disputed except in case of manifest, bona fide or inadvertent error.
            </li>
            <li>
              JK Tyre & Industries Ltd. does not have any obligation to reveal the value of points and rewards to the members JK
              Tyre holds the decision to change the product as per the availability and price escalation.
            </li>
            <li>
              Redeemed points will be adjusted against the ex-showroom price of the vehicle redeemed. Any charges towards
              registration, insurance, road taxes, accessories incurring additional charges over ex-showroom costs will be borne
              by the member
            </li>
            <li>
              Once a member choose a reward for redemption, he/ she cannot change it subsequently.
            </li>
            <li>
              JK Tyre & Industries Ltd. doesnâ€™t offer any guarantee/warranty on any of the items redeemed. Standard
              manufacturerâ€™s guarantee will be applicable on these items.
            </li>
            <li>
              Redemption can be made anytime during the year for point equivalent or less than the points accumulated at the
              time of redemption.
            </li>
            <li>
              Images shown in the redemption catalogue are representative and the actual product features may vary at the
              discretion of the manufacturer / marketer at the time the order is placed
            </li>
            <li>
              Items are subject to availability and JK Tyre & Industries Ltd. reserves the sole right to cancel/modify/suggest
              alternate model/brands etc. with any other items at any point in time without prior intimation to the members.
            </li>
            <li>
              The terms and condition of the program are governed by the law of India.
            </li>
            <li>
              The redemption gifts are valid for the points earned between 1st April 2019 and 31st march 2020.
            </li>
            <li>
              JK Tyre & Industries Ltd will have the right to verify any/all sales made during the scheme period whatever way it
              deems fit.
            </li>
            <li>
              For the issue related to discrepancy, damage or deficiency of items/products/voucher, the same needed to the
              intimated to the JK Advantage program team within 3 days of such receipts. However, in case of no such intimation
              by
              the member within this time period, the items, voucher shall be considered as valid and accepted and no queries or
              complains in this regards shall be entertain.
            </li>
            <li>
              If an item should arrived damaged, and if the damage is evident from the condition of the packaging, the member
              should refuse the shipment and obtain as refusal note from the courier company and send it to:

              <div>
                <div class="title-with-underline">The Program manager, JK Advantage</div>
                <div>H-609, Titanium City Center, 100 Ft Road, Satellite, Ahmedabad - 380015, Tele-8282884545</div>
                <div>Email: newjkadvantage@trackwalkins.com</div>
              </div>
            </li>
            <li>
              Delivery will be made only at the registered address. In case the mailing address has changed recently, please
              call the program team to request for a change in the registered address. Only after you receive a confirmation on
              change of registered address should you place a request for redemption of items points. Under no circumstances
              would the items be delivered to any address other than the registered addresses.
            </li>
            <li>
              Delivery will be made only against written acknowledgement of receipt of the items by any occupant at the
              registered address. Such acknowledgement shall be deemed to the acknowledgement of the member.
            </li>
          </ul>
        </div>
        {!isView && (
          <div className='d-flex justify-content-between p-3' style={{ width: "100%" }}>
            <div>
              <label className="custom-checkboax1--container">
                <input
                  type="checkbox"
                  name="completed"
                  checked={isAccept}
                  onChange={handleCheckboxChange}
                />
                <span className="checkmark1" />
              </label>
              <span style={{ marginLeft: "10px" }}>
                I agree to the terms and conditions
              </span>
            </div>
            <Button color="primary" disabled={!isAccept} onClick={handleSubmitTerms}>Continue</Button>
          </div>
        )}
      </ModalBody>
      {isView && (
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>Close</Button>
        </ModalFooter>
      )}
    </Modal>
  )
}
